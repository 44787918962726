import FetchService from '../utils/request';
import Const from '../utils/const';
class UserService {
  /**
   * Creates an instance of UserService.
   * @memberOf UserService
   */
  constructor() {
    this.module = 'user';
    this.fetchService = new FetchService({});
  }
  login(user) {
    return this.fetchService.post(`/action/login`, user).then(response => {
      return response;
    });
  }
  check(ssoTicket) {
    const _url = `/action/check` + (ssoTicket ? `?ticket=` + ssoTicket : ``);
    return this.fetchService.post(_url).then(response => {
      return response;
    });
  }
  wxLogin(redirectUrl) {
    const url = `/action/wxLogin?redirectUrl=${redirectUrl}&sysCode=` + Const.wxSysCode;
    return this.fetchService.post(url).then(response => {
      return response;
    });
  }

  /**
   * 获取用户头像
   * @returns Promise Ajax请求返回并处理后的Promise
   */
  getUserImage() {
    return this.fetchService.get(`/repo/kf-customer/find-user-image`).then(response => {
      return response;
    });
  }

  /**
   * 修改密码
   * @returns Promise Ajax请求返回并处理后的Promise
   */
  updatePwd(user) {
    return this.fetchService.post(`/repo/kf-customer/update-user`, user).then(response => {
      return response;
    });
  }
}
export default new UserService();