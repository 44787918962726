<template>
  <div class="v-propModel app" style="margin: 0">
    <div class="kingfisher-login-box" :style="{backgroundImage : bgImage}">
      <div class="kingfisher-login-info-box">
        <div class="kingfisher-login-con">
          <div class="kingfisher-login-title">{{ siteName }}</div>
          <div class="kingfisher-login-title2">{{ siteEnName }}</div>
          <div class="kingfisher-wx-qr-box" v-if="wxQrLogin">
            <div class="qr-view" id="wxQrLogin"></div>
            <div class="kingfisher-wx-qr-btn" @click="doStopWxQrLogin">
              <i class="iconfont icon-direction-right" style="font-size: 20px;"></i>
            </div>
          </div>
          <el-form class="kingfisher-login-input mt15" v-else :rules="model.data.rules" :model="model.data.user">
            <p class="error">{{ model.data.message }}</p>
            <el-form-item
                style="width: 100%;" class="wel_input_box"
                prop="username">
              <el-input
                  v-model="model.data.user.userName" :placeholder="$t('PleaseEnterAnAccountNumber')"
                  type="text"
                  @keydown.enter="loginFromKey" @change="onChange">
                <template><i class="iconfont  icon-user"></i></template>
              </el-input>
            </el-form-item>
            <el-form-item style="width: 100%;margin-top: 1rem" class="wel_input_box" prop="password">
              <el-input v-model="model.data.user.password"
                        :placeholder="$t('PleaseEnterThePassword')" type="password" @keydown.enter="loginFromKey"
                        @change="onChange">
                <template><i class="iconfont icon-mima"></i></template>
              </el-input>
            </el-form-item>
            <div class="login_btn_box"
                 style="width: 100%;margin-top: 1.5rem;display: flex;flex-direction: row;justify-content: space-between;align-items: center">
              <el-button type="warning" round @click="doLogin" :loading="model.data.tryLogin"
              style="width: 100%">{{ $t('Login') }}</el-button>
<!--              <el-button class="wx_btn" round @click="doWxQrLogin">{{ $t('WechatLogin') }}</el-button>-->
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getCurrentInstance, onMounted, ref} from 'vue'
  import {useRoute, useRouter} from 'vue-router'
  import {ElMessage} from 'element-plus'
  import 'url-search-params-polyfill'
  import {setToken, removeToken} from "@/utils/auth";
  import userModel from '../model/user'
  import oemManager from "@/utils/oem"
  import Const from '@/utils/const'
  import envModel from '@/model/env'
  import {sysCodeMapping} from '@/utils/sysMsg';

  export default {
  name: 'Welcome',
  setup() {
    const router = useRouter();
    const route = useRoute();
    let model = ref({
      data: {
        tryLogin: false,
        rules: {
          userName: [
            {required: true, message: Kf.t('UserNameCannotBeEmpty'), trigger: 'blur'}
          ],
          password: [
            {required: true, message: Kf.t('PasswordCannotBeEmpty'), trigger: 'blur'}
          ]
        },
        user: {
          userName: "",
          password: "",
          rememberMe: true
        },
        message: '',
        group: '',
        role: ''
      }
    });
    const wxQrLogin = ref(false);

    if (window.location.href.indexOf("http://localhost") === 0) {
      model.value.data.user.userName = "";
      model.value.data.user.password = "";
    }


    const doLogin = () => {
      if (!model.value.data.user.userName || !model.value.data.user.password) {
        ElMessage({type: 'warning', message: Kf.t('UsernameOrPasswordCannotBeEmpty'),group:true});
        return;
      }
      if (!model.value.data.tryLogin) {
        model.value.data.tryLogin = true;
        svsLogin();
      }
    };

    const doWxLogin = () => {
      /*redirectUrl: btoa(window.location.href), callback: (wxLoginUrl) => {
        if (wxLoginUrl && wxLoginUrl.startsWith('http')) {
          window.location.href = wxLoginUrl;
        } else {
          console.log(wxLoginUrl);
        }
      }*/
    }

    const doWxQrLogin = () => {
      wxQrLogin.value = true;
      window.Kf = window.Kf || {}
      window.Kf.onWxQrSuccess = (result) => {
        new WxLogin({
          id: 'wxQrLogin',
          appid: result.appId,
          scope: 'snsapi_login',
          redirect_uri: result.rdUrl,
          state: result.state,
        });
      };
      window.Kf.onWxQrFail = (msg) => {
        wxQrLogin.value = false;
        model.value.data.message = msg;
      };

      const qrLogin = () => {
        if (window.Kf.wechatLogin && window.Kf.ssoReady) {
          clearInterval(timer);
          window.Kf.wechatLogin();
        }
      }
      const timer = setInterval(qrLogin, 50);
    }
      console.log('环境变量111',process.env.VUE_APP_SPACE_PROJECT,process.env.NODE_ENV,process.env.VUE_APP_TEST_SERVER)
    const doStopWxQrLogin = () => {
      const _dom = document.getElementById('wxQrLogin');
      if (_dom) {
        _dom.innerHTML = '';
      }
      wxQrLogin.value = false;
    }

    const svsLogin = (ticket) => {
      window.Kf = window.Kf || {}
      window.Kf.onLoginSuccess = (result) => {
          setToken(result.kfSysMsg);
          if(result?.data){
              userModel.updateUser(result.data);
          }
        console.log("----user");
        console.log(result);

        localStorage.setItem('KC_USER', JSON.stringify(result.data));
        localStorage.setItem('KC_TOKEN', result.kfSysMsg);
        let resultData = result.data;
        model.value.data.tryLogin = false;
        model.value.data.message = "";
        if (resultData.userName) {
           console.log('环境变量',process.env.VUE_APP_SPACE_PROJECT)
            if(process.env.VUE_APP_SPACE_PROJECT){
                router.replace({path: '/content'});
            }else{
                router.replace({path: '/main'});
            }
        }
      }
      window.Kf.onLoginFail = (result) => {
        model.value.data.tryLogin = false;
        model.value.data.message = sysCodeMapping(result);
      }

      if (ticket) {
        localStorage.setItem('KC_TOKEN', ticket);
        let autoLogin = function() {
          if (window.Kf.login && window.Kf.ssoReady) {
            clearInterval(timer);
            window.Kf.login("", "");
          }
        }
        let timer = setInterval(autoLogin, 50);
      } else {
        let login = function() {
          if (window.Kf.login && window.Kf.ssoReady) {
            clearInterval(timer);
            window.Kf.login(model.value.data.user.userName, model.value.data.user.password);
          }
        }
        let timer = setInterval(login, 50);
      }
    }

    const loginFromKey = (event) => {
      if (event.keyCode === 13) {
        doLogin();
      }
    };
    const onChange = (value) => {
      model.value.data.tryLogin = false;
    };

    let swagger;

    const url = window.location.href;
    const params = new URLSearchParams(url.substring(url.indexOf('?') + 1));
    const ticket = params.get('ticket');

    if (ticket) {
      svsLogin();
    }

    let siteName = ref();
    let siteEnName = ref();
    let site = "space";
    let bgImage = ref();
    onMounted(() => {
        removeToken();
      oemManager.init(() => {
        console.log("切换产品信息")
        if (oemManager.products?.product?.loginPage) {
          window.location.href = oemManager.products?.product?.loginPage;
        }
          oemManager.dependPlatformType(function () {
              let _name = 'space'
              let name = oemManager.platformType.value === 'saas' ? ('saas' + _name) : _name;

              siteName.value = oemManager.products[name]["title"];
              siteEnName.value = oemManager.products[name]["enTitle"]
              document.title = oemManager.products[name]["title"];
              if (Const.develop) {
                  document.title = document.title + envModel.version
              }
              bgImage.value = `url('${oemManager.getImage(name, "bg", "space")}')`;

              let link = document.head.querySelector("link");
              link.href = oemManager.getImage(name, "icon", "space");
          })

      })


      let width = document.body.clientWidth;
      let fontSize = Math.floor(width / 1920 * 16);
      document.body.parentElement.style.fontSize = fontSize + "px";

      if (route.params.ticket) {
        svsLogin(route.params.ticket);
      }
    });
    return {
      model, loginFromKey, onChange, doLogin, swagger, doWxLogin, siteName, siteEnName,
      wxQrLogin, doWxQrLogin, doStopWxQrLogin,
      bgImage
    }


  },
  components: {},


}
</script>

<style scoped>
@import "../styles/css/login.css";
@import "../styles/css/icon/iconfont.css";

.login_btn_box .el-button {
  width: 45%;
  padding: 0;
}

.login_btn_box .wx_btn {
  background: #40E476;
  border-radius: 5px;
  border: none;
  color: #fff;
}

.el-input__inner {
  border-radius: 0.25rem;
  border: 0.0625rem solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.2);
  height: 5rem;
  line-height: 5rem;
  padding: 0 0.9375rem;
}

.v-s-login-main {
  height: 16.375rem;
  width: 25rem;
}

.error {
  font-size: 0.75rem;
  color: #f56c6c;
  height: 16px;
  padding: 0;
  margin-bottom: 5px;
}

.rem .el-checkbox__input .el-checkbox__inner {
  border: 1px solid white !important;
}

.rem {
  font-size: 0.1rem;
  color: #999999 !important;
}

.el-form-item {

  margin-bottom: 0.2rem !important;
}

.app {
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  /*margin-top: 1.25rem;*/
}

.v-bb .el-button.is-round {
  border-radius: 1.25rem;
  padding: 0.75rem 1.4375rem;
}

.v-bb .el-input {
  font-size: 0.875rem;
}

.v-bb .el-input::-webkit-scrollbar {
  width: 0.375rem;
}

.v-bb .el-input-group__append, .v-bb .el-input-group__prepend {
  border: 0.0625rem solid #121820;
  border-radius: 0.25rem;
  padding: 0 1.25rem;
  width: 0.0625rem;
}

.v-bb .el-input__inner {
  border-radius: 0.25rem;
  border: 0.0625rem solid #121820;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 0.9375rem;
}

.v-bb .el-button {
  border: 0.0625rem solid #e6a23c;
  padding: 0.75rem 1.25rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.v-bb .iconfont {
  font-size: 1rem;
}

.wel_input_box input {
  height: 4rem;
}

.kingfisher-login-input .wel_input_box .el-input-group__append, .kingfisher-login-input .wel_input_box .el-input-group__prepend {
  background: transparent !important;
  border: none !important;
}

.kingfisher-login-input .el-input__inner {
  background: rgba(255, 255, 255, 0.2) !important;
  border-color: rgba(255, 255, 255, 0.2) !important;

}

.kingfisher-login-input .el-button--warning {
  background: #01baff;
  border-radius: 5px;
  border: none;
}

.kingfisher-login-input input:-webkit-autofill {
  background-color: rgba(255, 255, 255, 0.2) !important;
  background-image: none !important;
  color: #ccc !important;
}
</style>
<style>
.kingfisher-login-input .el-input-group__append, .kingfisher-login-input .el-input-group__prepend {
  background: transparent;
  border: none;
}

.kingfisher-login-input .wel_input_box .el-input-group__append, .kingfisher-login-input .wel_input_box .el-input-group__prepend {
  background: rgba(255, 255, 255, 0.2) !important;
  color: #ccc;
  border: none !important;
}

.kingfisher-login-input .el-input__inner {
  border: 0.0625rem solid transparent !important;
  background: rgba(255, 255, 255, 0.2) !important;
  color: #ccc !important;
}

.kingfisher-login-input .el-input__prefix {
  width: 1rem;
}

.kingfisher-login-input .el-input__icon {
  width: 100%;
}

</style>
