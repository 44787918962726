import { ref } from 'vue';
import userService from '@/service/user';
let user = ref({});
let userScreenshot = ref('');
const updateUser = function (result) {
  if (result.userId) {
    user.value = result;
  }
  if ((result === null || result === void 0 ? void 0 : result.screenshot) === userScreenshot.value) {
    return false;
  }
  if (!user.value.userId) {
    router.replace({
      path: '/'
    });
  }
  userService.getUserImage().then(res => {
    user.value.screenshot = res;
    userScreenshot.value = res;
    localStorage.setItem('KC_USER', JSON.stringify(user.value));
  });
};
export default {
  user,
  updateUser,
  userScreenshot
};