export const sysCodeMapping = kfSysCode => {
  let msg = "";
  switch (kfSysCode) {
    case 0:
      {
        msg = Kf.t('OperationSuccessful');
        break;
      }
    case 101:
      {
        msg = Kf.t('UserNotRegistered');
        break;
      }
    case 102:
      {
        msg = Kf.t('UsernameOrPasswordIncorrect');
        break;
      }
    case 103:
      {
        msg = Kf.t('LoginTimedOut');
        break;
      }
    case 104:
      {
        msg = Kf.t('YourAccountHasBeenEnteredElsewhereAndYouAreForcedOffline');
        break;
      }
    case 105:
      {
        msg = Kf.t('LoginCredentialsNotFound');
        break;
      }
    case 106:
      {
        msg = Kf.t('YouHaveNotObtainedAProductAuthorization');
        break;
      }
    case 107:
      {
        msg = Kf.t('LoginCredentialsExpired');
        break;
      }
    case 111:
      {
        msg = Kf.t('TheCiphertextHasBeenCorruptedAndCannotBeResolved');
        break;
      }
    case 112:
      {
        msg = Kf.t('UserPrivillegeMissingPleaseContactAdministrator');
        break;
      }
    case 116:
      {
        msg = Kf.t('UserAccountExpired');
        break;
      }
    default:
      {
        msg = Kf.t('SystemErrorPleaseTryAgainLater');
      }
  }
  return msg;
};